import { debug } from 'debug'

import { debounce } from '@/utils/debounce'

const debugLoadIFrameObserver = debug('root-app:loadIFrameResizeObserver')

/**
 * Observes resizes and emits the new height to be received by a message event listener on any other window.
 *
 * Also changes overflow-y to hidden while executing, and initial shortly after. This way the scrollbar is always hidden.
 *
 * Automatically detects and only runs when in an iframe
 *
 * Also adds in-iframe class to body for iframe specific styling
 */
export function loadIFrameResizeObserver () {
  if (!inIframe()) {
    debugLoadIFrameObserver('no iFrame detected, skipping resizeObserver initialization')
    return
  }

  debugLoadIFrameObserver('iFrame detected, initiating observer')

  // add in-frame class to body
  document.body.classList.add('in-iframe')

  const iFrameResizeObserver = new ResizeObserver((entries) => {
    document.documentElement.style.overflowY = 'hidden'

    const enableOverflowY = debounce(() => {
      document.documentElement.style.overflowY = 'initial'
    }, 500)

    const updateMinHeightbehaviour = debounce(() => {
      if (document.body.classList.contains('page-cmmt')) {
        // We need to temporarily enable this on the cmmt page as it does not shrink, but it can grow,
        // causing empty space to appear below the footer
        // This happens because the dropdowns can cause the page to increase in size without pushing the footer down
        document.getElementsByTagName('main')[0].style.minHeight = '100vh'
      } else {
        // We need to unset the min-height on the main element to make sure the observer can observe the page shrinking
        document.getElementsByTagName('main')[0].style.minHeight = 'unset'
      }
    }, 500)

    if (document.documentElement.style.overflowY !== 'initial') {
      enableOverflowY()
    }

    updateMinHeightbehaviour()

    window.top?.postMessage(
      { clientHeight: entries[0].target.scrollHeight },
      '*'
    )
  })

  iFrameResizeObserver.observe(document.body)
}

const inIframe = () => {
  try {
    return window.self !== window.top
  } catch (e) {
    return false
  }
}
