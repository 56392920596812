import { debug } from 'debug'

import { SiteConfiguration } from '@olyslager/customer-configuration'
import { Language, languages as languageUtils } from '@olyslager/global-utilities'
const debugLoadDefaultLanguage = debug('root-app:loadDefaultLanguage')

export default function loadDefaultLanguage (siteConfiguration: SiteConfiguration, languages: Language[]) {
  debugLoadDefaultLanguage('Loading default language')

  const defaultLanguage = siteConfiguration.properties?.defaultLanguage as string
  if (defaultLanguage) {
    const language = languages.find(lang => lang.code === defaultLanguage || lang.code2 === defaultLanguage)
    if (language) {
      debugLoadDefaultLanguage('Default language is %O', language)
      languageUtils.setDefaultLanguage(language)
    }
  } else {
    debugLoadDefaultLanguage('Failed')
  }
}
