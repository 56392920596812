import { debug } from 'debug'

import { SiteConfiguration, defaults } from '@olyslager/customer-configuration'

import * as globalV1 from '../public/css/globalV1.css?inline'
import * as globalV2 from '../public/css/globalV2.css?inline'
import * as printV1 from '../public/css/print.css?inline'

import addCssToHead from './addCssToHead'

const debugLoadDefaultStyling = debug('root-app:loadDefaultStyling')

export async function loadDefaultStyling (moaVersion: number, siteConfiguration: SiteConfiguration): Promise<void> {
  debugLoadDefaultStyling('Loading default styling')

  if (moaVersion === 1) {
    addCssToHead(globalV1.default)
    addCssToHead(printV1.default)
  } else if (moaVersion === 2) {
    addCssToHead(globalV2.default)

    if (siteConfiguration.properties.showBackgroundImage === false) {
      document.querySelector('body')?.classList.add('bg-color')
    } else {
      const imageNumber = siteConfiguration.properties.backgroundImage || defaults.properties.backgroundImage
      const customBackgroundUrl = siteConfiguration.properties.backgroundImageCustom

      if (imageNumber) {
        const backgroundBlur = siteConfiguration.properties.backgroundBlur || defaults.properties.backgroundBlur
        const backgroundDarken = siteConfiguration.properties.backgroundDarken || defaults.properties.backgroundDarken

        let urlSm = ''
        let urlMd = ''

        if (imageNumber === defaults.backgroundImageNumberCustom && customBackgroundUrl) {
          urlSm = customBackgroundUrl
          urlMd = customBackgroundUrl
        } else {
          urlSm = `${import.meta.env.VITE_NETLIFY_CDN_URL}/backgrounds/bg-${imageNumber}-sm.jpg`
          urlMd = `${import.meta.env.VITE_NETLIFY_CDN_URL}/backgrounds/bg-${imageNumber}-md.jpg`
        }

        const head = document.head || document.getElementsByTagName('head')[0]
        if (head) {
          debugLoadDefaultStyling('Preloading background images')
          const linkSm = document.createElement('link')
          linkSm.as = 'image'
          linkSm.rel = 'preload'
          linkSm.fetchPriority = 'high'
          linkSm.href = urlSm
          linkSm.media = '(max-width: 640px)'
          head.appendChild(linkSm)
          const linkMd = document.createElement('link')
          linkMd.as = 'image'
          linkMd.rel = 'preload'
          linkMd.fetchPriority = 'high'
          linkMd.href = urlMd
          linkMd.media = '(min-width: 640px)'
          head.appendChild(linkMd)
        }

        addCssToHead(`:root {
        --background-image-sm: url('${urlSm}');
        --background-image-md: url('${urlMd}');
        --background-blur: ${backgroundBlur};
        --background-darken: ${backgroundDarken};
        }`)
      }
    }
  }

  debugLoadDefaultStyling('Loaded default styling')
}

export async function loadDefaultErrorStyling (): Promise<void> {
  debugLoadDefaultStyling('Loading default error styling')

  addCssToHead(globalV2.default)

  debugLoadDefaultStyling('Loaded default error styling')
}
