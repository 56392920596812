import { languages } from '@olyslager/global-utilities'

import getCurrentPage from '@/getCurrentPage'
import { Page } from '@/layouts/pages'

export function trackUrlChange () {
  const url = window.location

  // Check for old language path
  const isOldLanguagePath = url.pathname.length === 4 // '/eng', '/nel', etc
  let isOldLanguage = false
  if (isOldLanguagePath) {
    // Check if the path is an actual old language
    const language = languages.getLanguageByLocale(url.pathname.substring(1))
    isOldLanguage = language !== undefined
  }

  // Check root path
  const isRootPath = url.pathname === '/'

  // Only push the event when above situations are false
  if (!isRootPath && !isOldLanguage) {
    const currentPage: Page = getCurrentPage(location.pathname)

    // @ts-expect-error This works as the documentation: https://developers.google.com/tag-platform/tag-manager/datalayer#reset
    window.dataLayer?.push(function () {
      // @ts-expect-error See above
      this.reset()
    })

    window.dataLayer?.push({
      event: 'urlChange',
      pageTitle: currentPage.titleGtm
    })
  }
}
