/* eslint-disable @typescript-eslint/no-explicit-any */
export const debounce = (func: any, delay: number) => {
  let timeout: number | ReturnType<typeof setTimeout>

  return (...args: any) => {
    if (timeout) {
      clearTimeout(timeout)
    }
    timeout = setTimeout(() => {
      func(...args)
    }, delay)
  }
}
