import { debug } from 'debug'

import { SiteConfiguration } from '@olyslager/customer-configuration'

const debugPreloadLogo = debug('root-app:main:preloadLogo')

export default function preloadLogo (siteConfiguration: SiteConfiguration) : void {
  debugPreloadLogo('Preloading logo')

  const head = document.head || document.getElementsByTagName('head')[0]

  const frontend = siteConfiguration.frontends.find(frontend => frontend.name === '@olyslager/header')
  if (frontend && frontend.enabled && frontend.properties && frontend.properties.showLogo !== false && frontend.properties.logoUrl) {
    debugPreloadLogo('Logo url: %o', frontend.properties.logoUrl)

    if (head && frontend.properties.logoUrl) {
      const link = document.createElement('link')
      link.as = 'image'
      link.rel = 'preload'
      link.fetchPriority = 'high'
      link.href = frontend.properties.logoUrl
      head.appendChild(link)
      debugPreloadLogo('Preload link added')
      return
    }
  }
  debugPreloadLogo('Not preloading logo')
}
