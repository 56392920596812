import { debug } from 'debug'

const debugLoadGoogleFont = debug('root-app:loadGoogleFont')

export default async function loadGoogleFont (googleFont: string | undefined): Promise<void> {
  debugLoadGoogleFont('Loading Google Font')

  if (googleFont && googleFont.length > 0) {
    const head = document.head || document.getElementsByTagName('head')[0]

    const link = document.createElement('link')
    link.rel = 'preconnect'
    link.href = 'https://fonts.googleapis.com/'
    head.appendChild(link)

    const link2 = document.createElement('link')
    link2.rel = 'preconnect'
    link2.href = 'https://fonts.gstatic.com/'
    link2.crossOrigin = ''
    head.appendChild(link2)

    const link3 = document.createElement('link')
    link3.rel = 'stylesheet'
    link3.href = `https://fonts.googleapis.com/css2?family=${encodeURI(googleFont)}&display=swap`
    head.appendChild(link3)

    debugLoadGoogleFont(`Loaded Google Font '${googleFont}'`)
  } else {
    debugLoadGoogleFont('No Google Font found')
  }
}
