import debug from 'debug'

import { CountryCode, countries } from '@olyslager/global-utilities'

import getCountryFromIp from './ip2location/getCountryFromIp'

const debugLoadUserCountry = debug('root-app:loadUserCountry')

/**
 * Returns the users current country.
 * Tries to get the current country from localStorage first, otherwise fetches the country from the default country and finally gets it based on IP.
 * @returns iso2 country code or countries.unknownCountryCode when country is not found
 */
export default async function loadUserCountry (): Promise<string> {
  debugLoadUserCountry('Getting country from localstorage')
  const defaultCountry = countries.getDefaultCountry()
  const currentCountry = countries.getCurrentCountry()
  const currentVrnCountry = countries.getCurrentVrnCountry()

  if (!defaultCountry || !currentCountry || !currentVrnCountry) {
    debugLoadUserCountry('No country found, getting country from ip')
    const countryFromIp = await getCountryFromIp()

    if (countryFromIp === countries.unknownCountryCode) {
      debugLoadUserCountry('No country found from ip, storing unknown country')
      countries.setDefaultCountry(countries.unknownCountryCode)
      countries.setCurrentCountry(countries.unknownCountryCode)
      countries.setCurrentVrnCountry(countries.unknownCountryCode)
      return countries.unknownCountryCode
    }

    debugLoadUserCountry('Found a country from ip, storing user country')
    countries.setDefaultCountry(countryFromIp as CountryCode)
    countries.setCurrentCountry(countryFromIp as CountryCode)
    countries.setCurrentVrnCountry(countryFromIp as CountryCode)
    return countryFromIp
  }

  debugLoadUserCountry('Country "%O" found in localstorage', currentCountry.iso2)
  return currentCountry.iso2
}
