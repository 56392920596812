import { debug } from 'debug'

import { ConfigResult, getConfig } from '@olyslager/customer-configuration'

const debugGetCustomerConfig = debug('root-app:main:getCustomerConfig')

export default async function getCustomerConfig () : Promise<ConfigResult> {
  debugGetCustomerConfig('Loading customer configuration')
  const config: ConfigResult = await getConfig()
  if (!config) {
    debugGetCustomerConfig('Unable to find customer config')
  }

  // if (config.status !== 200) {
  //   debugGetCustomerConfig('Unable to load customer config')
  //   return null
  // }
  debugGetCustomerConfig('Customer configuration loaded')

  return config
}
