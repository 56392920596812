import { debug } from 'debug'
import { registerApplication } from 'single-spa'

import { storage } from '@olyslager/global-utilities'
const debugConfigurator = debug('root-app:loadConfigurator')

function canLoad () {
  const localStorageAvailable = storage.storageAvailable('localStorage')
  const sessionStorageAvailable = storage.storageAvailable('sessionStorage')
  if (!localStorageAvailable && !sessionStorageAvailable) {
    console.warn('Unable to load configurator, since local and session storage are not available')
    debugConfigurator('No storage')
    return false
  }

  // Check hostname, never load configurator on a production url, only on dev and test urls
  const hostname = window.location.hostname
  if (!hostname.startsWith('dev--') && // any dev environment
      !hostname.startsWith('.test.lubricantadvisor.com') && // any test environment
      !hostname.startsWith('test-') && // prod environment, test urls only
      !hostname.startsWith('localhost')) { // local development
    debugConfigurator('Invalid hostname')
    return false
  }

  // Load when there already is a session id in the sessionstorage
  if (storage.sessionStorage.getItem('sid')) {
    return true
  }

  // Check if there is a session id in the url
  const params = new URLSearchParams(window.location.search)
  const sid = params.get('sid')

  if (!sid) {
    // Do not load when there is no session id in the url
    debugConfigurator('No Session')
    return false
  }

  // save session id to sessionStorage
  storage.sessionStorage.setItem('sid', sid)

  // Remove the 'sid' param
  params.delete('sid')

  const query = params.toString()
  if (query) {
    window.history.replaceState({}, document.title, window.location.pathname + '?' + query)
  } else {
    window.history.replaceState({}, document.title, window.location.pathname)
  }

  return true
}

/**
 * Register the Configurator frontend
 */
export default async function loadConfigurator () : Promise<boolean> {
  debugConfigurator('Loading configurator')

  if (!canLoad()) {
    debugConfigurator('Not loading configurator')
    return false
  }

  // Create the configurator div
  const body = document.querySelector('body')
  if (body) {
    const elementConfigurator = document.createElement('div')
    elementConfigurator.id = 'configurator'
    body.appendChild(elementConfigurator)

    debugConfigurator('Registering configurator')

    // Register the frontend
    registerApplication({
      name: 'configurator',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      app: () => System.import('@olyslager/configurator'),
      activeWhen: '/',
      customProps: {
        domElement: elementConfigurator
      }
    })
  }

  debugConfigurator('Configurator registered')

  return true
}
