import { debug } from 'debug'

const debugAddCssToHead = debug('root-app:addCssToHead')
const error = debug('root-app:addCssToHead:error')

export default function addCssToHead (css: string) {
  const head = document.head || document.getElementsByTagName('head')[0]
  const style = document.createElement('style')
  if (head) {
    debugAddCssToHead('Adding style element to head')
    head.appendChild(style)
    debugAddCssToHead('Adding css to style element')
    style.appendChild(document.createTextNode(css))
    debugAddCssToHead('Added css')
  } else {
    error('Head element not found')
  }
}
