export type HistoryState = 'popstate'

export function registerHistoryListener (state: HistoryState, listenerFunction: () => void, startImmediate: boolean) {
  if (startImmediate) {
    listenerFunction()
  }

  window.addEventListener(state, () => {
    listenerFunction()
  })
}
