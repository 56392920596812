import { debug } from 'debug'

import { configClientGraphQL, gql } from '@olyslager/api-client'
import { CountryCode, countries } from '@olyslager/global-utilities'
const debugGetCountryFromIp = debug('root-app:getCountryFromIp')

interface Ip2LocationData {
  ip2Location: string;
}

/**
 * Fetches user country based on IP
 * @returns iso2 country code or - on failure
 */
export default async function getCountryFromIp (): Promise<CountryCode> {
  debugGetCountryFromIp('Getting country from ip')

  try {
    const queryResult = await configClientGraphQL.get<Ip2LocationData>(gql`
      query getCountryFromIp {
        ip2Location
      }
    `)

    if (queryResult.data) {
      debugGetCountryFromIp('Country found %O', queryResult.data)
      const country = queryResult.data.ip2Location?.toUpperCase() || countries.unknownCountryCode
      return country as CountryCode
    } else {
      debugGetCountryFromIp('No country found!')
      console.error('No country found!')
      return countries.unknownCountryCode
    }
  } catch (error) {
    debugGetCountryFromIp('Executing query failed! %O', error)
    console.error(error)
    return countries.unknownCountryCode
  }
}
