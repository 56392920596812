import { debug } from 'debug'

import { SiteDataConfiguration } from '@olyslager/customer-configuration'
import { Dataset, countries, datasets } from '@olyslager/global-utilities'

import loadUserCountry from '@/loadUserCountry'
const debugSetCurrentDataset = debug('root-app:setCurrentDataset')

export default async function setCurrentDataset (datasetList: Dataset[], siteDataConfiguration: SiteDataConfiguration | null) : Promise<Dataset | null> {
  debugSetCurrentDataset('Trying to set the current dataset')
  if (datasetList.length === 0) {
    debugSetCurrentDataset('No datasets found')
    return null
  }

  if (datasetList.length === 1) {
    debugSetCurrentDataset('Only 1 dataset found, setting current dataset to %O', datasetList[0])
    datasets.setCurrentDataset(datasetList[0])
    return datasetList[0]
  }

  debugSetCurrentDataset('Multiple datasets found: %O', datasetList)

  if (!siteDataConfiguration || !siteDataConfiguration.defaultDatasetCode) {
    debugSetCurrentDataset('No siteDataConfiguration found or no default dataset code set, setting current dataset to first in list: %O', datasetList[0])
    datasets.setCurrentDataset(datasetList[0])
    return datasetList[0]
  }

  debugSetCurrentDataset('Default dataset code is: %O', siteDataConfiguration.defaultDatasetCode)

  const defaultDataset = datasetList.find(dataset => dataset.code === siteDataConfiguration.defaultDatasetCode)
  if (!defaultDataset) {
    debugSetCurrentDataset('Default dataset is not found, setting current dataset to first in list: %O', datasetList[0].code)
    datasets.setCurrentDataset(datasetList[0])
    return datasetList[0]
  }

  if (siteDataConfiguration.countryMap?.length === 0) {
    debugSetCurrentDataset('Multiple datasets found without country map, setting current dataset to default dataset: %O', defaultDataset.code)
    datasets.setCurrentDataset(defaultDataset)
    return defaultDataset
  }

  debugSetCurrentDataset('Country map is: %O', siteDataConfiguration.countryMap)

  debugSetCurrentDataset('Loading user location')
  const location = await loadUserCountry()
  debugSetCurrentDataset('Country: %O', location)
  if (location === countries.unknownCountry.iso2) {
    debugSetCurrentDataset('No country found, setting current dataset to default dataset: %O', defaultDataset.code)
    datasets.setCurrentDataset(defaultDataset)
    return defaultDataset
  }

  let datasetCodeFromCountryMap
  siteDataConfiguration.countryMap.forEach(countrymap => {
    const hasCountry = countrymap.countries.includes(location)
    if (hasCountry) {
      datasetCodeFromCountryMap = countrymap.datasetCode
    }
  })

  if (!datasetCodeFromCountryMap) {
    debugSetCurrentDataset('Country not found in countrymap, setting current dataset to default dataset: %O', defaultDataset.code)
    datasets.setCurrentDataset(defaultDataset)
    return defaultDataset
  }

  debugSetCurrentDataset('Dataset code from country map found: %O', datasetCodeFromCountryMap)

  const currentDataset = datasetList.find(dataset => dataset.code === datasetCodeFromCountryMap)

  if (!currentDataset) {
    debugSetCurrentDataset('Dataset code from country map not found in dataset list, setting current dataset to default dataset: %O', defaultDataset.code)
    datasets.setCurrentDataset(defaultDataset)
    return defaultDataset
  }

  debugSetCurrentDataset('Setting current dataset to: %O', currentDataset.code)
  datasets.setCurrentDataset(currentDataset)

  return currentDataset
}
