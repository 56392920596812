import { SingleSpaCustomEventDetail } from 'single-spa'

import { TranslationDictionary } from '@olyslager/customer-configuration'

import { Page, defaultPage, pages } from './layouts/pages'

export function setDocumentTitle (translations: TranslationDictionary) {
  window.addEventListener('single-spa:before-routing-event', (evt) => {
    const detail: SingleSpaCustomEventDetail = (<CustomEvent>evt).detail

    const newUrl = new URL(detail.newUrl)

    const currentPage: Page = pages.find(p => newUrl.pathname.match(p.route)) || defaultPage

    const page = translations.pages[currentPage.titleTranslatorKey]
    const websiteTitle = translations.pages['website-title']
    const pageTitle = `${page} - ${websiteTitle}`

    document.title = pageTitle
  })
}
