import { debug } from 'debug'

import { dataClient, gql } from '@olyslager/api-client'
import { Dataset, datasets } from '@olyslager/global-utilities'
const debugLoadDatasets = debug('root-app:loadDatasets')

interface DatasetData {
  datasets: Dataset[];
}

export default async function loadDatasets (): Promise<Dataset[]> {
  debugLoadDatasets('Loading datasets')

  try {
    const queryResult = await dataClient.get<DatasetData>(gql`
      query getDatasets {
        datasets {
          code
        }
      }
    `)

    const datasetList = queryResult.data?.datasets
    if (datasetList) {
      datasets.setDatasets(datasetList)
      debugLoadDatasets('Datasets loaded %O', datasetList)
      return datasetList
    } else {
      debugLoadDatasets('No datasets found!')
      console.error('No datasets found!')
      return []
    }
  } catch (error) {
    debugLoadDatasets('Executing query failed! %O', error)
    console.error(error)
    return []
  }
}
