import { Page, defaultPage, pages } from './layouts/pages'

/**
 * Gets the current page based on the provided path name
 * @param path The url pathname
 */
export default function getCurrentPage (path: string): Page {
  const currentPage = pages.find(p => path.match(p.route))

  return currentPage || defaultPage
}
