import { debug } from 'debug'
import { start } from 'single-spa'

import { ConfigResult, defaults } from '@olyslager/customer-configuration'
import { generateErrorTemplate, languages as languageUtils, loadGtm, siteConfigurationCache, siteDataConfigurationCache, translations } from '@olyslager/global-utilities'

import * as baseCss from '../public/css/base.css?inline'

import addCssToHead from './addCssToHead'
import loadDatasets from './datasets/loadDatasets'
import setCurrentDataset from './datasets/setCurrentDataset'
import { loadFavicons } from './favicon/loadFavicons'
import getCustomerConfig from './getCustomerConfig'
import { registerHistoryListener } from './historylisteners'
import { addCurrentPageBodyClass } from './historylisteners/addCurrentPageBodyClass'
import { trackUrlChange } from './historylisteners/trackUrlChange'
import { loadDefaultLanguage, loadLanguages } from './languages'
import { canLoad, loadConfigurator } from './loadConfigurator'
import { loadDefaultErrorStyling, loadDefaultStyling } from './loadDefaultStyling'
import { loadFrontends } from './loadFrontends'
import loadGoogleFont from './loadGoogleFont'
import { loadRootCssVars } from './loadRootCssVars'
import loadUserCountry from './loadUserCountry'
import { createManifest } from './manifest/createManifest'
import { loadIFrameResizeObserver } from './observers/iFrameResizeObserver'
import preloadLogo from './preloadLogo'
import isReferrerAllowed from './referrerSecurity'
import { setDocumentTitle } from './setDocumentTitle'
import setMoaVersion from './setMoaVersion'
import { getLanguageToFetch, getTranslations, mergeCustomerTranslations } from './translations'

const debugMain = debug('root-app:main')

// eslint-disable-next-line no-console
console.debug(`${APP_NAME}: v${APP_VERSION}`)

;(async function () {
  debugMain('%c🛢 Olyslager MOA 🛢', 'color: orange; font-size: 30px;')

  addCssToHead(baseCss.default)

  const config: ConfigResult = await getCustomerConfig()

  if (!config.siteConfiguration) {
    const body = document.querySelector('body')
    let main = document.querySelector('main#maincontent')
    // Create main element when it's not found
    if (!main && body) {
      main = document.createElement('main')
      main.id = 'maincontent'
      body.appendChild(main)
    }

    if (config.error) {
      console.error(config.error)
      // Load v2 default styling for the error page
      loadDefaultErrorStyling()

      if (main) {
        main.innerHTML = generateErrorTemplate(new Error('Config error'))
      }
    }
  } else {
    const siteConfiguration = config.siteConfiguration
    const siteDataConfiguration = config.siteDataConfiguration
    const moaVersion = siteConfiguration.properties.moaVersion || defaults.properties.moaVersion

    // Check for redirect url and then send the visitor away 👋
    // Do not redirect when the configurator is available or else you can never turn the redirect off again 😱
    if ((!canLoad() || window.location.search.includes('isredirecttest')) && siteConfiguration.properties.redirectUrl) {
      window.location.replace(siteConfiguration.properties.redirectUrl)
      return
    }

    setMoaVersion(moaVersion)

    preloadLogo(siteConfiguration)

    loadDefaultStyling(moaVersion, siteConfiguration)

    const favicons = loadFavicons(siteConfiguration.properties?.favicons)
    createManifest(siteConfiguration.properties?.webManifestTitle, { source192: favicons?.['android-chrome-192x192'], source512: favicons?.['android-chrome-512x512'] })

    siteConfigurationCache.set(siteConfiguration)
    if (siteDataConfiguration) {
      siteDataConfigurationCache.set(siteDataConfiguration)
    }

    if (isReferrerAllowed(siteConfiguration.referrerSecurity)) {
      loadGtm(siteConfiguration.properties.gtmContainerId)

      registerHistoryListener('popstate',
        () => addCurrentPageBodyClass(), true)
      registerHistoryListener('popstate',
        () => trackUrlChange(), true)

      await loadUserCountry()

      loadGoogleFont(siteConfiguration.properties.googleFont)

      loadRootCssVars(moaVersion, siteConfiguration.properties, siteConfiguration.colors || {})

      const datasetList = await loadDatasets()
      await setCurrentDataset(datasetList, siteDataConfiguration)

      const languages = await loadLanguages()
      loadDefaultLanguage(siteConfiguration, languages)

      const languageToFetch = getLanguageToFetch(languages)
      languageUtils.setCurrentLanguage(languageToFetch)

      const fetchedTranslations = await getTranslations(languageToFetch.code2)
      const mergedTranslations = mergeCustomerTranslations(fetchedTranslations, siteConfiguration.translations, languageToFetch.code2)

      translations.setTranslations(mergedTranslations)

      setDocumentTitle(mergedTranslations)

      // If the language we are fetching is read rtl, set the proper property on the body
      if (languageToFetch.code2 === 'ar') {
        document.body.dir = 'rtl'
      }

      await loadFrontends(siteConfiguration.frontends, siteConfiguration.properties, siteConfiguration)
    } else {
      // No single spa layout is loaded, so no main, create this now.
      const body = document.querySelector('body')
      let main = document.querySelector('main#maincontent')
      if (!main && body) {
        main = document.createElement('main')
        main.id = 'maincontent'
        body.appendChild(main)
      }
    }

    loadIFrameResizeObserver()
  }

  if (!config.error) {
    // Load configurator as the last frontend, even if everything failed before this, except when there is an actual error
    await loadConfigurator()
  }

  debugMain('Starting root app')
  start()
  debugMain('Started')
}())
