export declare type Page = {
  cssClassName: string;
  titleTranslatorKey: string;
  titleGtm: string;
  route: string;
}

export const pageHome: Page = {
  cssClassName: 'home',
  titleTranslatorKey: 'home',
  titleGtm: 'Home',
  route: '/'
}

export const pageCmmt: Page = {
  cssClassName: 'cmmt',
  titleTranslatorKey: 'home',
  titleGtm: 'Home',
  route: '/category'
}

export const pageSearchResults: Page = {
  cssClassName: 'searchresults',
  titleTranslatorKey: 'search',
  titleGtm: 'Search',
  route: '/search'
}

export const pageRecommendation: Page = {
  cssClassName: 'recommendation',
  titleTranslatorKey: 'recommendation',
  titleGtm: 'Recommendation',
  route: '/recommendation/\\d*[/]?$'
}

export const pageReport: Page = {
  cssClassName: 'report',
  titleTranslatorKey: 'recommendation',
  titleGtm: 'Report',
  route: '/report'
}

export const pageCountryLanguageSelection: Page = {
  cssClassName: 'country-language-selection',
  titleTranslatorKey: 'country-language-selection',
  titleGtm: 'Country & Language Selection',
  route: '/language'
}

export const defaultPage = pageHome

export const pages: Page[] = [
  pageCmmt,
  pageSearchResults,
  pageRecommendation,
  pageReport,
  pageCountryLanguageSelection
]
