import { debug } from 'debug'
import { RegisterApplicationConfig, registerApplication } from 'single-spa'
import { constructApplications, constructLayoutEngine, constructRoutes } from 'single-spa-layout'

import { Frontend, RootProperties, SiteConfiguration, defaults } from '@olyslager/customer-configuration'

import layoutV1 from './layouts/moaLayout.v1.html?raw'
import layoutV2 from './layouts/moaLayout.v2.html?raw'
import { setHeaderClasses } from './setHeaderClasses'

const debugLoadFrontends = debug('root-app:loadFrontends')

const allApplications = new Map<string, RegisterApplicationConfig>()

export async function loadFrontends (frontends: Frontend[], properties: RootProperties, siteConfiguration: SiteConfiguration) : Promise<void> {
  debugLoadFrontends('Loading frontends %O', frontends)
  // Wrap frontend loading in a try/catch to make sure the configurator
  // doesn't get skipped when there is an error.
  try {
    const moaVersion = properties.moaVersion || defaults.properties.moaVersion
    const moaLayouts = ['', layoutV1, layoutV2]

    const data = {
      props: {
        siteConfiguration
      },
      loaders: {}
    }

    const routes = constructRoutes(moaLayouts[moaVersion], data)
    const applications = constructApplications({
      routes,
      loadApp ({ name }) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return System.import(name)
      }
    })

    constructLayoutEngine({ routes, applications })

    setHeaderClasses(siteConfiguration)

    for (const application of applications) {
      const frontend = frontends.find(f => f.name === application.name)
      if (frontend?.enabled) {
        registerApplication(application)
      }

      allApplications.set(application.name, application)
    }
  } catch (error) {
    debugLoadFrontends('Error when loading frontends', error)
  }

  debugLoadFrontends('All frontends loaded')
}
