import debug from 'debug'

import { Language, languages as languageUtils } from '@olyslager/global-utilities'

const debugGetLanguageToFetch = debug('root-app:getLanguageToFetch')

/**
 * Gets language from url if it exists.
 * If it does not, get browser language or default language.
 * Also checks if the language is allowed.
 * @param allowedLanguages Array of allowed languages
 * @returns 2 character language code of Language interface (code2)
 */
export default function getLanguageToFetch (allowedLanguages: Language[]): Language {
  const langFromUrl = window.location.pathname.substring(1).split('/')[0]

  debugGetLanguageToFetch('Trying to fetch language from URL: ' + langFromUrl)

  const languageFromLocaleInUrl = languageUtils.getLanguageByLocale(langFromUrl, true)

  if (languageFromLocaleInUrl) {
    if (allowedLanguages.find((o) => o.code2 === languageFromLocaleInUrl.code2)) {
      debugGetLanguageToFetch(`Found locale "${languageFromLocaleInUrl.code2}" in allowedLanguages`)
      return languageFromLocaleInUrl
    }

    debugGetLanguageToFetch(`Locale ${languageFromLocaleInUrl.code2} was found, but not present in allowedLanguages`)
  }

  debugGetLanguageToFetch('Could not find language from URL or in allowedLanguages. Returning browser or default language')
  return languageUtils.getBrowserOrDefaultLanguage()
}
