import { debug } from 'debug'

import { ReferrerSecurity } from '@olyslager/customer-configuration'
import { generateErrorTemplate } from '@olyslager/global-utilities'

const debugReferrerSecurity = debug('root-app:referrerSecurity')

export default function isReferrerAllowed (referrerSecurity: ReferrerSecurity | null): boolean {
  // any referrer is allowed when the security is not in the config or it is disabled
  if (!referrerSecurity || !referrerSecurity.enabled) {
    debugReferrerSecurity('Referrer security is disabled')
    return true
  }

  debugReferrerSecurity('Referrer security is enabled')

  const referrer = document.referrer ? new URL(document.referrer).hostname : ''

  // Any referrer is allowed when visiting the site from an allowed host,
  // for example a test url
  if (referrerSecurity.allowListHosts &&
      referrerSecurity.allowListHosts.includes(location.hostname)) {
    debugReferrerSecurity(`Hostname "${location.hostname}" is included in allowListHosts, so allow access`)
    return true
  }

  let denied = false

  // Deny access if the allow list has items but the referrer is not in it
  if (referrerSecurity.allowList &&
      referrerSecurity.allowList.length > 0 &&
      !referrerSecurity.allowList.includes(referrer)) {
    debugReferrerSecurity(`Referrer "${referrer}" is not included in allowList, so deny access`)
    denied = true
  }

  // Deny access if the referrer is included in the deny list
  if (referrerSecurity.denyList &&
      referrerSecurity.denyList.includes(referrer)) {
    debugReferrerSecurity(`Referrer "${referrer}" is included in denyList, so deny access`)
    denied = true
  }

  if (denied) {
    if (referrerSecurity.deniedRedirectUrl) {
      // Redirect to another website if access is denied
      debugReferrerSecurity(`Access denied, redirecting to "${referrerSecurity.deniedRedirectUrl}"`)
      window.location.replace(referrerSecurity.deniedRedirectUrl)
    } else {
      // Show access denied message
      debugReferrerSecurity('Access denied, showing error template')
      const main = document.querySelector('body>main')
      if (main) {
        main.innerHTML = generateErrorTemplate(new Error('Access denied'))
      }
    }
    return false
  }
  return true
}
