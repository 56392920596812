import { debug } from 'debug'

const debugLoadFavicons = debug('root-app:loadFavicons')

export type SupportedFavIcons = {
  '16x16': string,
  '32x32': string,
  'apple-touch-icon': string,
  'android-chrome-192x192': string,
  'android-chrome-512x512': string
}
const sortedFavicons: SupportedFavIcons = {
  '16x16': '',
  '32x32': '',
  'apple-touch-icon': '',
  'android-chrome-192x192': '',
  'android-chrome-512x512': ''
}

export function loadFavicons (faviconSources: string[] | undefined): SupportedFavIcons | undefined {
  if (!faviconSources || faviconSources.length === 0) {
    debugLoadFavicons('No customer favicons found, do nothing')
    return
  }

  debugLoadFavicons('Generating favicon HTML elements')

  faviconSources.forEach(faviconUrl => {
    const fileNameInUrl = faviconUrl.split('/').pop()

    if (!fileNameInUrl) return

    Object.keys(sortedFavicons).forEach(iconType => {
      if (fileNameInUrl.includes(iconType)) {
        sortedFavicons[iconType] = faviconUrl
      }
    })
  })

  updateElementAttribute('head [sizes="16x16"]', sortedFavicons['16x16']!, 'href')
  updateElementAttribute('head [sizes="32x32"]', sortedFavicons['32x32']!, 'href')
  updateElementAttribute('head [sizes="180x180"]', sortedFavicons['apple-touch-icon']!, 'href')

  return sortedFavicons
}

/**
 * Updates the element attribute with the provided link, selected by query
 * @param query queryselector to get the element with
 * @param link link to icon to update the attribute with
 * @param attribute attribute to be updated
 */
function updateElementAttribute (query: string, link: string, attribute: string): void {
  const element = document.querySelector(query) as HTMLLinkElement

  if (element !== null) {
    element[attribute] = link
  }
}
