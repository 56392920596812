import { debug } from 'debug'

import { SiteConfiguration, defaults } from '@olyslager/customer-configuration'

import * as globalV1 from '../public/css/globalV1.css?inline'
import * as globalV2 from '../public/css/globalV2.css?inline'
import * as printV1 from '../public/css/print.css?inline'

import addCssToHead from './addCssToHead'

const debugLoadDefaultStyling = debug('root-app:loadDefaultStyling')

export async function loadDefaultStyling (moaVersion: number, siteConfiguration: SiteConfiguration): Promise<void> {
  debugLoadDefaultStyling('Loading default styling')

  if (moaVersion === 1) {
    addCssToHead(globalV1.default)
    addCssToHead(printV1.default)
  } else if (moaVersion === 2) {
    addCssToHead(globalV2.default)

    const rootValues: {
      key: string,
      value: string | number
    }[] = []

    // if max-width is used
    if (siteConfiguration.properties.useMaxWidth !== false) {
      // add max-width class to main and set value
      document.querySelector('body')?.classList.add('max-width')
      const maxWidth = siteConfiguration.properties.maxWidth || defaults.properties.maxWidth
      if (maxWidth !== defaults.properties.maxWidth) {
        rootValues.push({
          key: 'page-max-width',
          value: maxWidth + 'px'
        })
      }
    }

    if (siteConfiguration.properties.paddingSm && siteConfiguration.properties.paddingSm !== defaults.properties.paddingSm) {
      document.querySelector('body')?.classList.add('padding')
      rootValues.push({
        key: 'page-padding-sm',
        value: siteConfiguration.properties.paddingSm + 'px'
      })
    }
    if (siteConfiguration.properties.paddingMd && siteConfiguration.properties.paddingMd !== defaults.properties.paddingMd) {
      document.querySelector('body')?.classList.add('padding')
      rootValues.push({
        key: 'page-padding-md',
        value: siteConfiguration.properties.paddingMd + 'px'
      })
    }
    if (siteConfiguration.properties.paddingLg && siteConfiguration.properties.paddingLg !== defaults.properties.paddingLg) {
      document.querySelector('body')?.classList.add('padding')
      rootValues.push({
        key: 'page-padding-lg',
        value: siteConfiguration.properties.paddingLg + 'px'
      })
    }

    if (siteConfiguration.properties.showBackgroundImage === false) {
      document.querySelector('body')?.classList.add('bg-color')
    } else {
      const imageNumber = siteConfiguration.properties.backgroundImage || defaults.properties.backgroundImage
      const customBackgroundUrl = siteConfiguration.properties.backgroundImageCustom

      if (imageNumber) {
        if (siteConfiguration.properties.backgroundBlur !== undefined && siteConfiguration.properties.backgroundBlur !== defaults.properties.backgroundBlur) {
          rootValues.push({ key: 'background-blur', value: siteConfiguration.properties.backgroundBlur })
        }

        if (siteConfiguration.properties.backgroundDarken !== undefined && siteConfiguration.properties.backgroundDarken !== defaults.properties.backgroundDarken) {
          rootValues.push({ key: 'background-darken', value: siteConfiguration.properties.backgroundDarken })
        }

        let urlSm = ''
        let urlMd = ''

        if (imageNumber === defaults.backgroundImageNumberCustom && customBackgroundUrl) {
          urlSm = customBackgroundUrl
          urlMd = customBackgroundUrl
        } else {
          urlSm = `${import.meta.env.VITE_NETLIFY_CDN_URL}/backgrounds/bg-${imageNumber}-sm.jpg`
          urlMd = `${import.meta.env.VITE_NETLIFY_CDN_URL}/backgrounds/bg-${imageNumber}-md.jpg`
        }

        rootValues.push({ key: 'background-image-sm', value: `url('${urlSm}')` })
        rootValues.push({ key: 'background-image-md', value: `url('${urlMd}')` })

        const head = document.head || document.getElementsByTagName('head')[0]
        if (head) {
          debugLoadDefaultStyling('Preloading background images')
          const linkSm = document.createElement('link')
          linkSm.as = 'image'
          linkSm.rel = 'preload'
          linkSm.fetchPriority = 'high'
          linkSm.href = urlSm
          linkSm.media = '(max-width: 640px)'
          head.appendChild(linkSm)
          const linkMd = document.createElement('link')
          linkMd.as = 'image'
          linkMd.rel = 'preload'
          linkMd.fetchPriority = 'high'
          linkMd.href = urlMd
          linkMd.media = '(min-width: 640px)'
          head.appendChild(linkMd)
        }
      }
    }

    let values = ''
    rootValues.forEach((setting) => {
      values += `--${setting.key}: ${setting.value};`
    })

    if (values.length) {
      addCssToHead(`:root { ${values} }`)
    }
  }

  debugLoadDefaultStyling('Loaded default styling')
}

export async function loadDefaultErrorStyling (): Promise<void> {
  debugLoadDefaultStyling('Loading default error styling')

  addCssToHead(globalV2.default)

  debugLoadDefaultStyling('Loaded default error styling')
}
