export async function fetchTranslations (
  endpoint: string
) {
  return fetch(TRANSLATIONS_URL + endpoint, { method: 'GET' })
    .then((response) => {
      if (response.ok) {
        return response.json()
      }
      throw new Error('Something went wrong fetching the translations')
    })
    .then((responseJson) => {
      return responseJson
    })
    .catch((error) => {
      throw new Error(error)
    })
}
