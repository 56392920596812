import { debug } from 'debug'

import { TranslationDictionary, Translations } from '@olyslager/customer-configuration'

import { fetchTranslations } from './fetchTranslations'

const debugLoadLanguages = debug('root-app:loadLanguages')

/**
 * @param locale 2 character language code (code2 in Language interface)
 * @returns translations object for that locale
 */
export async function getTranslations (locale: string): Promise<Translations> {
  debugLoadLanguages('Loading languages with locale: ' + locale)

  const translations: TranslationDictionary = await fetchTranslations(`/${locale}.json`)
  return { [locale]: translations }
}

function deepMerge (source, mergeMe) {
  for (const key in mergeMe) {
    if (Object.prototype.hasOwnProperty.call(mergeMe, key)) {
      if (mergeMe[key] instanceof Object && source[key] instanceof Object) {
        source[key] = deepMerge(source[key], mergeMe[key])
      } else {
        source[key] = mergeMe[key]
      }
    }
  }
  return source
}

/**
 * Merges default translations with the customer translations and returns them.
 * If there are no customer translations then it just returns the default translations.
 * @param source Source translations
 * @param customerTranslations Customer translations
 * @param locale 2 character language code (code2 in Language interface)
 * @returns Merged translations
 */
export function mergeCustomerTranslations (source: Translations, customerTranslations: Translations, locale: string): TranslationDictionary {
  if (customerTranslations && customerTranslations[locale]) {
    const merged = deepMerge(source[locale], customerTranslations[locale])
    return merged
  }

  return source[locale]
}
