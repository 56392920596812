import { debug } from 'debug'

import { dataClient, gql } from '@olyslager/api-client'
import { Language, languages } from '@olyslager/global-utilities'
const debugLoadLanguages = debug('root-app:loadLanguages')

interface LanguageData {
  languages: Language[];
}

export default async function loadLanguages (): Promise<Language[]> {
  debugLoadLanguages('Loading languages')

  try {
    const queryResult = await dataClient.get<LanguageData>(gql`
      query getLanguages {
        languages {
          code
          code2
          name
          localName
        }
      }
    `)

    const languageList = queryResult.data?.languages
    if (languageList) {
      languages.setLanguages(languageList)
      debugLoadLanguages('Languages loaded %O', languageList)
      return languageList
    } else {
      debugLoadLanguages('No languages found!')
      console.error('No languages found!')
      return []
    }
  } catch (error) {
    debugLoadLanguages('Executing query failed! %O', error)
    console.error(error)
    return []
  }
}
