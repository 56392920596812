import { debug } from 'debug'

const debugCreateManifest = debug('root-app:createManifest')

/**
 * Creates JSON manifest based on current android-chrome icons that are being generated (192x192 and 512x512)
 *
 * @returns JSON manifest
 */
export function createManifest (webManifestTitle: string | undefined, icons: { source192?: string, source512?: string }) {
  debugCreateManifest('Creating manifest')

  const manifest = {
    name: webManifestTitle ?? 'Lubricant advisor',
    display: 'standalone',
    orientation: 'any',
    scope: window.location.origin + '/',
    start_url: window.location.origin + '/',
    background_color: '#ffffff',
    theme_color: '#ffffff',
    icons: [
      {
        src: icons.source192 ?? window.location.origin + '/assets/android-chrome-192x192.png',
        sizes: '192x192',
        type: 'image/png'
      },
      {
        src: icons.source512 ?? window.location.origin + '/assets/android-chrome-512x512.png',
        sizes: '512x512',
        type: 'image/png'
      }
    ]
  }

  const manifestLink = document.createElement('link') as HTMLLinkElement
  manifestLink.setAttribute('rel', 'manifest')
  manifestLink.href = `data:application/manifest+json,${encodeURIComponent(JSON.stringify(manifest))}`
  document.head.appendChild(manifestLink)

  debugCreateManifest('Manifest created %O', manifest)
}
