import { debug } from 'debug'

import { Colors, RootProperties, defaults } from '@olyslager/customer-configuration'

import addCssToHead from './addCssToHead'

const debugLoadDefaultStyling = debug('root-app:loadDefaultStyling')

export async function loadRootCssVars (moaVersion: number, properties: RootProperties, colors: Colors): Promise<void> {
  debugLoadDefaultStyling('Loading root css vars')

  let fontValue = ''
  if (properties.googleFont) {
    fontValue = `'${properties.googleFont}', ${defaults.properties.bodyWebFont}`
  } else {
    fontValue = `${properties.bodyWebFont || defaults.properties.bodyWebFont}`
  }

  if (moaVersion === 1) {
    addCssToHead(`:root {
      --main-color-1: ${colors['main-color-1'] || '#4f4d4e'};
      --main-color-2: ${colors['main-color-2'] || '#999999'};
      --main-color-3: ${colors['main-color-3'] || '#4f4d4e'};
      --main-color-4: ${colors['main-color-4'] || '#fbb801'};
      --main-color-5: ${colors['main-color-5'] || '#747373'};
      --main-color-6: ${colors['main-color-6'] || '#4f4d4e'};
      --main-input-color-1: ${colors['main-input-color-1'] || '#ffffff'};
      --main-input-color-2: ${colors['main-input-color-2'] || '#4f4d4e'};
      --main-input-color-3: ${colors['main-input-color-3'] || '#999999'};
      --main-input-color-4: ${colors['main-input-color-4'] || '#dedede'};
      --main-font-color-1: ${colors['main-font-color-1'] || '#ffffff'};
      --main-font-color-2: ${colors['main-font-color-2'] || '#fbb801'};
      --main-font-color-3: ${colors['main-font-color-3'] || '#c1c1c1'};
      --main-font-color-4: ${colors['main-font-color-4'] || '#000000'};
      --main-font-color-5: ${colors['main-font-color-5'] || '#ffffff'};
      --main-icon-color-1: ${colors['main-icon-color-1'] || '#ffffff'};
      --main-icon-color-2: ${colors['main-icon-color-2'] || '#000000'};
      --main-icon-color-3: ${colors['main-icon-color-3'] || '#ffffff'};
      --main-icon-color-4: ${colors['main-icon-color-4'] || '#ffffff'};
      --main-icon-color-5: ${colors['main-icon-color-5'] || '#fbb801'};
      --main-defaultbutton-font-color: ${colors['main-defaultbutton-font-color'] || '#fbb801'};
      --main-defaultbutton-background-color: ${colors['main-defaultbutton-background-color'] || '#4f4d4e'};
      --main-togglebutton-on-font-color: ${colors['main-togglebutton-on-font-color'] || '#ffffff'};
      --main-togglebutton-on-background-color: ${colors['main-togglebutton-on-background-color'] || '#fbb801'};
      --main-togglebutton-off-font-color: ${colors['main-togglebutton-off-font-color'] || '#fbb801'};
      --main-togglebutton-off-background-color: ${colors['main-togglebutton-off-background-color'] || '#747373'};
      --main-togglebutton-off-hover-font-color: ${colors['main-togglebutton-off-hover-font-color'] || '#ffffff'};
      --main-togglebutton-off-hover-background-color: ${colors['main-togglebutton-off-hover-background-color'] || '#fbb801'};
      --body-background-gradient-start: ${colors['body-background-gradient-start'] || '#ffffff'};
      --body-background-gradient-end: ${colors['body-background-gradient-end'] || '#cccccc'};
      --body-font-family: ${fontValue};
    }`)
  } else if (moaVersion === 2) {
    let borderRadiusValue = ''
    borderRadiusValue = `${properties.borderRadius || defaults.properties.borderRadius}px`
    if (properties.showBorderRadius === false) {
      borderRadiusValue = '0px'
    }

    addCssToHead(`:root {
      --body-font-family: ${fontValue};
      --background-gradient-start: ${colors['background-gradient-start'] || defaults.colors.backgroundGradientStart};
      --background-gradient-end: ${colors['background-gradient-end'] || defaults.colors.backgroundGradientEnd};
      --color-primary: ${colors.primary || defaults.colors.primary};
      --color-secondary: ${colors.secondary || defaults.colors.secondary};
      --color-tertiary: ${colors.tertiary || defaults.colors.tertiary};
      --border-radius: ${borderRadiusValue};
    }`)
  }

  debugLoadDefaultStyling('Loaded root css vars')
}
