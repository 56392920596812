import { debug } from 'debug'

import getCurrentPage from '@/getCurrentPage'
import { Page } from '@/layouts/pages'

const debugaddCurrentPageBodyClass = debug('root-app:addCurrentPageBodyClass')

let previousPageName: string

export function addCurrentPageBodyClass () {
  debugaddCurrentPageBodyClass('Adding current page class to the body')
  const currentPage: Page = getCurrentPage(location.pathname)

  const className = makeClassName(currentPage.cssClassName)

  if (className !== previousPageName) {
    if (previousPageName) {
      document.body.classList.remove(previousPageName)
    }
    document.body.classList.add(className)
    previousPageName = className
  }
  debugaddCurrentPageBodyClass('Adding class \'%s\' to the body', className)
}

export function makeClassName (pageName: string) {
  const className = 'page-' + pageName.toLowerCase()
  return className
}
