import debug from 'debug'

import { SiteConfiguration } from '@olyslager/customer-configuration'

const debugSetHeaderClasses = debug('root-app:main:setHeaderClasses')

export function setHeaderClasses (siteConfiguration: SiteConfiguration) {
  debugSetHeaderClasses('Setting header classes')

  const frontend = siteConfiguration.frontends.find(frontend => frontend.name === '@olyslager/header')

  if (!frontend || !frontend.enabled || !frontend.properties) {
    return
  }

  const header = document.querySelector('main > header')!

  if (frontend.properties.showLogo !== false && frontend.properties.logoUrl) {
    header.classList.add('has-logo')
  }

  if (siteConfiguration.properties.moaVersion !== 2) {
    if (frontend.properties.showLanguageSelection !== false) {
      header.classList.add('has-language-selection')
    }
  } else {
    header.classList.add('has-header')
  }
}
